import { db } from "../firebase/firebase.js"

export default {
    async getGroup(client_id, group_code) {
        return await db
            .collection("clients")
            .doc(client_id)
            .collection("groupCodes")
            .doc(group_code)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    var data = doc.data()
                    return data
                }
            })
            .catch((err) => {
                return null
            })
    },
    async getLink(client_id, table, type) {
        return await db
            .collection("clients")
            .doc(client_id)
            .collection("tables")
            .doc(table)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    var data = doc.data()
                    return data[type]
                }
            })
            .catch((err) => {
                return null
            })
    },

    async activateCode(client_id, groupCode, date) {
        return await db
            .collection("clients")
            .doc(client_id)
            .collection("groupCodes")
            .doc(groupCode)
            .update({ activated: date })
            .catch((err) => {
                return null
            })
    },



    async getClient(id) {
        return await db
            .collection("urls")
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    var data = doc.data()
                    return data
                }
            })
            .catch((err) => {
                return null
            })
    },
}
