<template>
  <div class="box" style="background-color: rgb(42, 55, 150)">
    <p style="color: white" class="bottom-marge">{{ text }}</p>

    <template v-if="!isCorrect">
      <div class="input-div">
        <input
          class="right-margin"
          style="text-transform: lowercase"
          type="text"
          placeholder="X98374SDF"
          ref="groupscode"
        />
        <div
          style="background-color: rgb(198, 110, 0)"
          @click="checkCode()"
          class="button"
        >
          {{ submit }}
        </div>

        <p v-if="!isCorrect && buttonClicked" style="color: red; font-size: 12px">
          Sorry that code is not correct!
        </p>
      </div>
    </template>
    <template v-else>
      <div
        style="background-color: rgb(198, 110, 0)"
        @click="goToGroupPage()"
        class="button"
      >
        {{ order }}
      </div>
    </template>
  </div>
</template>

<script>
import db from "../db/methods/READ.js"

export default {
  props: ["client_id", "table"],
  data: () => {
    return {
      text: "Op rekening",
      order: "Order here",
      submit: "Check",
      isCorrect: false,
      group: null,
      groupLink: null,
      arrangement_time: 180,
      buttonClicked: false,
    }
  },
  methods: {
    async goToGroupPage() {
      window.location.href = this.groupLink
    },

    async checkCode() {
      this.buttonClicked = false
      this.input_groupscode = this.$refs.groupscode.value.toLowerCase()

      if (this.inputIsGiven(this.input_groupscode)) {
        const response = await db.getGroup(this.client_id, this.input_groupscode)

        if (response == null) {
          this.isCorrect = false
          this.buttonClicked = true
          return
        } else {
          this.group = response.groupID

          if (response.activated == null) {
            await db.activateCode(this.client_id, this.input_groupscode, Date.now())
          }
        }

        if (this.group != null && this.dateCorrect(response.activated)) {
          this.groupLink = await db.getLink(this.client_id, this.table, this.group)
          this.isCorrect = true
          window.location.href = this.groupLink
        } else {
          this.isCorrect = false
        }
      }

      this.buttonClicked = true
    },

    inputIsGiven(variable) {
      if (variable != null && variable != "") {
        return true
      }
      return false
    },

    dateCorrect(unix_timestamp) {
      if (unix_timestamp == null) {
        return true
      } else {
        return true
      }

      // const time_between = (Date.now() - unix_timestamp) / 60 / 1000

      // if (time_between < this.arrangement_time) {
      //   return true
      // }
      // return false
    },
  },
}
</script>

<style scoped></style>
