<template>
  <div class="div" style="background-color: rgb(255, 232, 223); min-height: 100vh">
    <template v-if="showback == null"> </template>
    <template v-else-if="!showback">
      <div class="column">
        <DirectLink :link="paidLink" />
        <GroupLink :client_id="client_id" :table="table" />
      </div>
    </template>
    <template v-else>
      <div>Go Back</div>
    </template>
  </div>
</template>

<script>
import DirectLink from "../components/DirectLink.vue"
import GroupLink from "../components/GroupLink.vue"

import db from "../db/methods/READ.js"

export default {
  components: { DirectLink, GroupLink },
  data: () => {
    return {
      paidLink: null,

      client_id: "",
      table: "",

      showback: null,
    }
  },
  async created() {
    const id = window.location.href.split("/").pop()
    const clientData = await db.getClient(id)
    if (clientData == null) {
      this.showback = true
      return
    } else {
      this.showback = false
    }

    this.client_id = clientData.client_id
    this.table = clientData.table

    this.paidLink = await db.getLink(this.client_id, this.table, "url")
  },
  methods: {},
}
</script>

<style scoped>
.column {
  max-width: 800px;
  width: 100%;
}

.div {
  padding: 0% 5%;
}
</style>
