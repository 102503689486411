import config from "./config.js";


import firebase from "firebase/app";
import "firebase/firestore";

firebase.initializeApp(config);
const db = firebase.firestore();


export { db }

