<template>
  <div
    @click="goToPaidOrder()"
    class="box margin-bottom"
    style="background-color: rgb(42, 55, 150); color: white"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  props: ["link"],
  data: () => {
    return {
      text: "Order here",
    }
  },
  methods: {
    goToPaidOrder() {
      window.location.href = this.link
    },
  },
}
</script>

<style scoped>
.box {
  cursor: pointer;
}
</style>
